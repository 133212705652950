import React, { useEffect } from "react"
import "./experience.scss"

const experiences = [
  {
    position: "Full Stack Developer",
    company: "Versatile Innovation Labs (ViLabs)",
    tasks: [
      "Assisted in the requirements elicitation process.",
      "Worked alongside graphic designers to ensure the technical feasibility of new UI/UX designs and built the frontend of the Families Share web app.",
      "Designed, developed and tested the RESTful API used by the Families Share app.",
      "Handled deployment of multiple dockerized instances of the web app.",
      "Ensured the continuous integration and continuous delivery of the web app.",
      "Created a hybrid android application using expo.",
    ],
    skills: ["React", "Express", "Mongo", "Redux", "React Native"],
    period: "09/2018 - 03/2020",
  },
  {
    position: "Full Stack Developer - Research Associate (part-time)",
    company: "Intelligent Systems & Software Engineering Labgroup (ISSEL)",
    tasks: [
      "Developed the frontend of the two platforms of the Sities Project.",
      "Built and tested microservices and the core backend of the project.",
      "Optimized the platforms maximum speed (lazy loading, code splitting).",
      "Created and managed databases.",
      "Used sockets and a message broker to communicate with the elsa home assistant.",
    ],
    skills: [
      "React",
      "Express",
      "Mongo",
      "Redux",
      "Websockets",
      "Rabbitmq",
      "Microservices",
    ],
    period: "06/2019 - 03/2020",
  },
  {
    position: "Front End Developer",
    company: "Ixly B.V.",
    tasks: [
      "Worked alongside graphic designers to ensure the technical feasibility of new UI/UX designs and features.",
      "Updated the look and feel of many company applications.",
      "Have contributed (further developed, tested and maintained) almost in all company applications.",
      "Handled minor and major releases.",
    ],
    skills: ["Ruby", "Rails", "React", "Amazon S3"],
    period: "04/2020 - 06/2022",
  },
  {
    position: "Senior Software Engineer",
    company: "EPAM Systems",
    tasks: [],
    skills: ["React", "Java"],
    period: "09/2022 - Present",
  },
]

function Experience() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.previousElementSibling.classList.toggle(
            "show",
            entry.isIntersecting
          )
          entry.target.classList.toggle("show", entry.isIntersecting)

          if (entry.isIntersecting) observer.unobserve(entry.target)
        })
      },
      {
        threshold: 0.3,
      }
    )

    const cards = document.querySelectorAll(".experience-card")
    cards.forEach((card) => observer.observe(card))
  }, [])

  return (
    <section className="experiences" id="experience">
      <h2 className="experiences-title">EXPERIENCE</h2>
      <div className="experiences-container">
        {experiences.map(({ position, company, tasks, period, skills }, eIndex) => (
          <React.Fragment key={eIndex}>
            <div className="experience-timeline" style={{ "--row": eIndex }}>
              <div className="experience-period">{period}</div>
              <div
                className={`experience-dot${
                  eIndex === experiences.length - 1 ? " present" : ""
                }`}
              />
              <div className="experience-line" />
            </div>
            <div
              className="experience-card"
              style={{ "--column": eIndex % 2 === 0 ? 1 : 3, "--row": eIndex }}
            >
              <h2 className="experience-position">{position}</h2>
              <h3 className="experience-company">{company}</h3>
              <ul className="experience-tasks">
                {tasks.map((task, tIndex) => (
                  <li key={tIndex}>{task}</li>
                ))}
              </ul>
              <ul className="experience-skills">
                {skills.map((skill, sIndex) => (
                  <li className="pill" key={sIndex}>
                    {skill}
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  )
}

export default Experience
