import React from "react"
import "./scroll-to-top.scss"
import { Link } from "react-scroll"
import Rocket from "../svg/rocket"

function ScrollToTop() {
  return (
    <Link offset={-50} activeClass="active" to="header" spy={true} smooth={true}>
      <div className="scroll-to-top hidden">
        <Rocket />
      </div>
    </Link>
  )
}

export default ScrollToTop
