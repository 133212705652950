import React, { useEffect, useState } from "react"
import "./donate.scss"
import EthereumCoin from "../../assets/images/ethereum.png"
import PiggyBank from "../../assets/images/piggy-bank.svg"
import { ethers } from "ethers"
import { donationsAbi, donationsAddress } from "../../utils/constants"

const connectToContract = (ethereum) => {
  if (!ethereum) return

  const provider = new ethers.providers.Web3Provider(ethereum)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(donationsAddress, donationsAbi, signer)
  return contract
}

function Donate() {
  const { ethereum } = window
  const donationsContract = connectToContract(ethereum)

  const [isConnected, setIsConnected] = useState(false)
  const [account, setAccount] = useState(null)

  const checkIfWalletConnected = async () => {
    if (!ethereum) return

    setIsConnected(true)

    const accounts = await ethereum.request({ method: "eth_accounts" })

    if (accounts.length) {
      setAccount(accounts[0])
    }
  }

  const connectWallet = async () => {
    try {
      const accounts = await ethereum.request({ method: "eth_requestAccounts" })
      setAccount(accounts[0])
    } catch (error) {
      console.log(error)
    }
  }

  const donate = async () => {
    const chainId = await window.ethereum.request({ method: "eth_chainId" })
    if (chainId !== "0x3") return alert("Please connect to ropsten")

    const overrides = {
      value: ethers.utils.parseEther("0.01"),
    }
    try {
      await donationsContract.donate(overrides)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    checkIfWalletConnected()
  }, [])

  const initiateDonation = () => {
    if (!isConnected) return alert("Please install metamask")

    if (account) {
      donate()
    } else {
      connectWallet()
    }
  }

  return (
    <section className="blue donate">
      <button onClick={initiateDonation}>
        <img src={EthereumCoin} alt="ethereum coin" />
        <img src={PiggyBank} alt="piggy bank" />
      </button>
    </section>
  )
}

export default Donate
