import React, { useLayoutEffect, useState } from "react"
import "./navbar.scss"
import { Link } from "react-scroll"
import Code from "../svg/code"
import Menu from "../svg/menu"

const scrollPosition = () => window.pageYOffset || document.documentElement.scrollTop

function Navbar() {
  const [isOpen, setIsOpen] = useState(false)
  const toggleNavbar = () => setIsOpen(!isOpen)
  const closeNavbar = () => setIsOpen(false)

  useLayoutEffect(() => {
    let lastScroll = scrollPosition()

    const styleNavbarOnscroll = () => {
      const currentScroll = scrollPosition()

      const navbar = document.querySelector(".navbar")
      const scrollToTop = document.querySelector(".scroll-to-top")

      if (currentScroll > lastScroll) {
        if (window.scrollY > 150) {
          navbar.classList.add("scroll-down")
          navbar.classList.remove("scroll-up")
          scrollToTop.classList.remove("hidden")
        }
      } else {
        if (window.scrollY < 200) {
          navbar.classList.remove("scroll-up")
          scrollToTop.classList.add("hidden")
        } else {
          navbar.classList.add("scroll-up")
          navbar.classList.remove("scroll-down")
        }
      }

      lastScroll = currentScroll
    }

    window.addEventListener("scroll", styleNavbarOnscroll)
    return () => {
      window.removeEventListener("scroll", styleNavbarOnscroll)
    }
  }, [])

  return (
    <nav className="navbar">
      <a href="#main" className="skiplink">
        Skip to main content
      </a>
      <div className="brand">
        <Code />
        <div className="divider-vertical" />
      </div>
      <div className={`toggle${isOpen ? " active" : ""}`} onClick={toggleNavbar}>
        <Menu />
      </div>
      <ul className={`${isOpen ? "open" : ""}`}>
        <li>
          <Link
            onClick={toggleNavbar}
            offset={-60}
            to="about"
            spy={true}
            smooth={true}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            onClick={closeNavbar}
            offset={-60}
            to="links"
            spy={true}
            smooth={true}
          >
            Links
          </Link>
        </li>
        <li>
          <Link
            onClick={closeNavbar}
            offset={-60}
            to="experience"
            spy={true}
            smooth={true}
          >
            Experience
          </Link>
        </li>
        <li>
          <Link
            onClick={closeNavbar}
            offset={-60}
            to="projects"
            spy={true}
            smooth={true}
          >
            Projects
          </Link>
        </li>
        <li>
          <Link
            onClick={closeNavbar}
            offset={-60}
            to="contact"
            spy={true}
            smooth={true}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
