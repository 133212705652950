import React from "react"
import "./header.scss"
import Laptop from "../../assets/images/header.jpg"
import LaptopTablet from "../../assets/images/header-tablet.jpg"
import LaptopPhone from "../../assets/images/header-phone.jpg"

function Header() {
  return (
    <header className="header" id="header">
      <div className="overlay" />
      <picture>
        <source srcSet={Laptop} media="(min-width: 768px)" />
        <source srcSet={LaptopTablet} media="(min-width: 480px)" />
        <img src={LaptopPhone} alt="Laptop" />
      </picture>
      <div className="title">
        <div>Dimitris Tampakis</div>
        <div className="typewriter">Software Engineer</div>
      </div>
    </header>
  )
}

export default Header
