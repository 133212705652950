import React from "react"
import "./footer.scss"

function Footer() {
  return (
    <footer className="footer">
      <p className="copyright">
        <b>@lamouchefatale.com 2021 | All rights reserved</b>
        <br />
        Built with React
      </p>
    </footer>
  )
}

export default Footer
