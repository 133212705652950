import React from "react"
import PropTypes from "prop-types"

function Button({ type, onClick, label, disabled }) {
  return (
    <button type={type} onClick={onClick} disabled={disabled}>
      {label}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  type: "button",
  onClick: () => {},
  disabled: false,
}

export default Button
