import React from "react"
import PropTypes from "prop-types"

const getLabelText = (label, required) => {
  let text = `${label}`

  if (required) {
    text += "*"
  }

  return text
}

function TextArea({
  required,
  label,
  name,
  rows,
  error,
  register,
  labelClass,
  inputClass,
}) {
  return (
    <>
      <label htmlFor={name} className={labelClass}>
        {getLabelText(label, required)}
      </label>
      <textarea
        {...register(name)}
        name={name}
        rows={rows}
        id={name}
        className={inputClass}
      />
      <p data-testid={`${name}-error`}>{error?.message}</p>
    </>
  )
}

TextArea.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  register: PropTypes.func.isRequired,
}

TextArea.defaultProps = {
  inputClass: null,
  labelClass: null,
  required: false,
  type: "text",
  rows: 5,
  error: null,
}

export default TextArea
