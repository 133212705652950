import "../../styles/app.scss"
import React from "react"
import Navbar from "../navbar"
import Header from "../header"
import Container from "../container"
import About from "../about"
import Links from "../links"
import Experience from "../experience"
import Projects from "../projects"
import Donate from "../donate"
import Contact from "../contact"
import Footer from "../footer"
import ScrollToTop from "../scroll-to-top"

function App() {
  return (
    <Container>
      <ScrollToTop />
      <Navbar />
      <Header />
      <main id="#main">
        <About />
        <Links />
        <Experience />
        <Projects />
        <Donate />
        <Contact />
      </main>
      <Footer />
    </Container>
  )
}

export default App
