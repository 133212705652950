import * as React from "react"

function Code(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 59.834 59.834"
      width="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M29.917 0C13.421 0 0 13.421 0 29.917s13.421 29.917 29.917 29.917 29.917-13.421 29.917-29.917S46.413 0 29.917 0zm0 56.834C15.075 56.834 3 44.759 3 29.917 3 15.075 15.075 3 29.917 3c14.842 0 26.917 12.075 26.917 26.917 0 14.842-12.075 26.917-26.917 26.917z" />
      <path d="M24.531 29.636c-.018-.095-.03-.187-.067-.277-.037-.092-.098-.171-.154-.255-.043-.065-.065-.139-.12-.199-.016-.017-.038-.023-.054-.04-.016-.016-.022-.039-.04-.054l-7.5-6.866a1.5 1.5 0 10-2.025 2.213l6.292 5.759-6.292 5.759a1.5 1.5 0 102.025 2.212l7.5-6.865c.01-.009.013-.021.022-.031.023-.021.049-.038.071-.063.055-.06.077-.134.12-.199.056-.084.117-.163.154-.255.037-.09.049-.183.067-.277.019-.095.042-.185.042-.281s-.023-.187-.041-.281zM44.25 35.282H26.917a1.5 1.5 0 100 3H44.25a1.5 1.5 0 000-3z" />
    </svg>
  )
}

const MemoCode = React.memo(Code)
export default MemoCode
