import * as React from "react"

function Github(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 512 512"
      width="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M169.175 272.861c-24.725 0-35.169 32.773-35.169 52.803 0 20.031 10.444 52.807 35.169 52.807 24.727 0 35.171-32.775 35.171-52.807 0-20.029-10.445-52.803-35.171-52.803zM345.221 272.861c-24.726 0-35.17 32.773-35.17 52.803 0 20.031 10.444 52.807 35.17 52.807s35.171-32.775 35.171-52.807c0-20.029-10.446-52.803-35.171-52.803z" />
      <path d="M448.624 167.828c4.888-14.853 7.379-30.475 7.379-46.191 0-20.604-4.599-30.954-13.992-49.641-42.933 0-70.724 8.145-103.306 33.733-25.109-6.039-51.176-8.818-77.052-8.818-28.652 0-57.211 2.971-85.003 9.584-33.062-25.876-60.854-34.5-104.267-34.5-9.295 18.687-13.991 29.037-13.991 49.641 0 15.716 2.396 31.624 7.379 46.767C39.321 199.165 26 237.115 26 277.27c0 30.571 5.366 63.248 19.358 91.042 37.758 74.27 143.558 71.681 216.198 71.681 71.492 0 171.351 1.725 207.672-71.681 13.702-28.08 16.77-60.471 16.77-91.042.001-40.154-11.019-78.392-37.374-109.442zm-168.57 254.917h-46.191c-67.083 0-144.133-12.938-144.133-97.081 0-42.069 25.589-79.157 70.438-79.157 18.209 0 35.746 3.258 53.667 5.75 14.376 2.204 28.652 3.066 43.22 3.066 14.661 0 28.942-.862 43.223-3.066 18.207-2.492 35.553-5.75 53.665-5.75 44.851 0 70.437 37.087 70.437 79.157-.002 84.238-76.955 97.081-144.326 97.081z" />
    </svg>
  )
}

const MemoGithub = React.memo(Github)
export default MemoGithub
