import React from "react"
import PropTypes from "prop-types"

function Form({ onSubmit, children }) {
  return (
    <form data-testid="contact-form" onSubmit={onSubmit}>
      {children}
    </form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default Form
