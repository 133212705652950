import React from "react"
import "./about.scss"
import ProgrammerVideo from "../../assets/videos/programmer.webm"
import ProgrammerImage from "../../assets/images/programmer.png"

function About() {
  return (
    <section className="grey" id="about">
      <video
        poster={ProgrammerImage}
        className="video"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={ProgrammerVideo} />
      </video>
      <div className="about">
        <h2>ABOUT</h2>
        <p>
          Dimitrios Tampakis is a Senior Software Engineer with a passion in creating
          experiences, interfaces and services. He holds a Diploma in Electrical and
          Computer Engineering, which he received from the Aristotle University. He
          is currently working at EPAM Systems, a company that specializes in service
          development, digital platform engineering, and digital product design.
        </p>
        <div className="divider" />
        <p>
          His interests include Software Development, Machine Learning, Blockchain
          and Web Development. He is an enthusiastic learner and likes to participate
          in hackathons and online courses, seeing them as an opportunity to hone his
          skills. He loves coding in Python, Ruby and especially in Javascript.
        </p>
      </div>
    </section>
  )
}

export default About
