import * as React from "react"

function Cv(props) {
  return (
    <svg
      height="1em"
      viewBox="0 0 512 512"
      width="1em"
      fill="currentColor"
      {...props}
    >
      <path d="M398.6 169.2c-.9-2.2-2-4.3-3.5-6.1l-83.8-91.7c-1.9-2.1-4.2-3.6-6.7-4.9-2.9-1.5-6.1-2.1-9.5-2.1H135.2c-12.4 0-22.7 10.6-22.7 23.9v335.2c0 13.4 10.3 24.9 22.7 24.9h243.1c12.4 0 22.2-11.5 22.2-24.9V179.4c0-3.6-.5-7.1-1.9-10.2zm-238.1 9.4c0-1.5 1.8-2.1 3.4-2.1h70.8c1.6 0 2.8.6 2.8 2.1v10.8c0 1.4-1.1 3.1-2.8 3.1h-70.8c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm0 128c0-1.5 1.8-2.1 3.4-2.1h122.2c1.6 0 2.4.6 2.4 2.1v10.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8zm160 74.8c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h154.2c1.6 0 2.4.6 2.4 2.1v10.8zm32-128c0 1.4-.7 3.1-2.4 3.1H163.9c-1.6 0-3.4-1.7-3.4-3.1v-10.8c0-1.5 1.8-2.1 3.4-2.1h186.2c1.6 0 2.4.6 2.4 2.1v10.8zm-46.9-75.9c-5.6 0-11.1-5.2-11.1-11.3v-66l71.2 77.3h-60.1z" />
    </svg>
  )
}

const MemoCv = React.memo(Cv)
export default MemoCv
