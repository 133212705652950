import React from "react"
import "./links.scss"
import Github from "../svg/github"
import Instagram from "../svg/instagram"
import Linkedin from "../svg/linkedin"
import Codepen from "../svg/codepen"
import Cv from "../svg/cv"

const links = [
  {
    href: "https://github.com/dimitamp",
    icon: <Github />,
    label: "github",
  },
  {
    href: "https://www.linkedin.com/in/dimitrios-tampakis-538144175",
    icon: <Linkedin />,
    label: "linkedin",
  },
  {
    href: "https://www.instagram.com/lamouchefatale/",
    icon: <Instagram />,
    label: "instagram",
  },
  {
    href: "https://codepen.io/dimitamp",
    icon: <Codepen />,
    label: "codepen",
  },
  {
    href: "https://drive.google.com/uc?export=download&id=1kdePbQHPJTgZGM8_ZdxIk8Ff6ZUgkfv6",
    icon: <Cv />,
    label: "cv",
  },
]

function Links() {
  return (
    <section className="blue social-links" id="links">
      <div>
        {links.map(({ href, icon, label }, index) => (
          <a
            key={index}
            aria-label={label}
            target="_blank"
            href={href}
            rel="noreferrer"
          >
            {icon}
          </a>
        ))}
      </div>
    </section>
  )
}

export default Links
