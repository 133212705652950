import React from "react"
import "./projects.scss"
import SVG from "../svg"
import { LazyLoadImage } from "react-lazy-load-image-component"
import FamiliesShare from "../../assets/images/familiesshare.png"
import SurveryDonkey from "../../assets/images/surveydonkey.png"
import SirVoteAlot from "../../assets/images/sirvotealot.png"
import Sythes from "../../assets/images/sythes.png"
import ReactIntroSlider from "../../assets/images/reactintroslider.png"
import KafekopteioXenias from "../../assets/images/kafekopteioxenias.png"
import SessionsManagement from "../../assets/images/sessionsmanagement.png"
import Authenticationmicroservice from "../../assets/images/authenticationmicroservice.png"
import Wordle from "../../assets/images/wordle.png"
import Calipedia from "../../assets/images/calipedia.png"

const projects = [
  {
    title: "Families Share",
    photo: FamiliesShare,
    description:
      "A platform about sharing time and tasks related to childcare, parenting, after-school and leisure activities and other household tasks.",
    technologies: ["react", "mongo", "redux", "express", "node"],
    link: "https://play.google.com/store/apps/details?id=com.vilabs.families_share_development",
  },
  {
    title: "React Intro Slider",
    photo: ReactIntroSlider,
    description: "Easy-to-use yet very configurable app introduction slider.",
    technologies: ["react", "npm"],
    link: "https://codesandbox.io/embed/cocky-voice-l8ti1",
  },
  {
    title: "Authentication Microservice",
    photo: Authenticationmicroservice,
    description:
      "A microservice, implemented in GraphQL,that provides authentication / authorization features.",
    technologies: ["graphql", "mongo", "node"],
    link: "https://github.com/dimitamp/authentication-microservice-graphql",
  },
  {
    title: "Sythes",
    photo: Sythes,
    description:
      "A platform about supporting people in the third age by leveraging smart devices.",
    technologies: ["node", "react", "redux", "mongo"],
    link: "https://platform.sities-project.gr/en/signin",
  },
  {
    title: "Sir Vot-a-lot",
    photo: SirVoteAlot,
    description: "A simple poll application",
    technologies: ["redux", "react"],
    link: "https://sirvotealot.netlify.app/",
  },
  {
    title: "Survey Donkey",
    photo: SurveryDonkey,
    description: "A simple survey listing application",
    technologies: ["redux", "react"],
    link: "https://surveydonkey.netlify.com/",
  },
  {
    title: "Sessions Management",
    photo: SessionsManagement,
    description:
      "A platform about organizing and managing client sessions. Try it out with admin/admin.",
    technologies: ["graphql", "react", "mongo", "node", "redux"],
    link: "https://sessions-management.netlify.app",
  },
  {
    title: "Kafekopteio Xenias",
    photo: KafekopteioXenias,
    description:
      "E-shop built for a local business, with blogpost and newsletter functionality.",
    technologies: ["wordpress", "css", "php"],
    link: "https://kafekopteio-xenias.gr/",
  },
  {
    title: "Wordle",
    photo: Wordle,
    description: "Clone of the popular game Wordle",
    technologies: ["html", "javascript", "css"],
    link: "https://lamouchefatale-wordle.netlify.app/",
  },
  {
    title: "Calipedia",
    photo: Calipedia,
    description: "Knowledge library for calisthenics skills and progressions.",
    technologies: ["react", "expo", "graphql", "mongo"],
    link: "https://play.google.com/store/apps/details?id=com.lamouchefatale.calipedia",
  },
]

function Projects() {
  return (
    <section className="grey projects" id="projects">
      <div className="projects-title">
        <h2>PROJECTS</h2>
        <h3>Some of my projects</h3>
      </div>
      {projects.map(({ title, description, technologies, link, photo }) => (
        <article className="project-card" key={title}>
          <div className="project-card-inner">
            <div className="project-card-front">
              <h3 className="project-title">{title}</h3>
              <p className="project-description">{description}</p>
              <ul className="project-technologies">
                {technologies.map((technology) => (
                  <li key={technology}>
                    <SVG className="project-technology" name={technology} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="project-card-back">
              <div className="project-overlay" />
              <LazyLoadImage
                className="project-screenshot"
                alt={`${title} screenshot`}
                src={photo}
              />
              <a
                target="_blank"
                href={link}
                className="project-link"
                rel="noreferrer"
              >
                View Project
              </a>
            </div>
          </div>
        </article>
      ))}
    </section>
  )
}

export default Projects
