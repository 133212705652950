import * as React from "react"

function Menu(props) {
  return (
    <svg
      data-name="\u0421\u043B\u043E\u0439 1"
      viewBox="0 0 128 128"
      width="1em"
      fill="currentColor"
      height="1em"
      {...props}
    >
      <path d="M64 128a64 64 0 1164-64 64.07 64.07 0 01-64 64zM64 4a60 60 0 1060 60A60.07 60.07 0 0064 4z" />
      <path d="M95 44H33a2 2 0 010-4h62a2 2 0 010 4zM95 66H33a2 2 0 010-4h62a2 2 0 010 4zM95 88H33a2 2 0 010-4h62a2 2 0 010 4z" />
    </svg>
  )
}

const MemoMenu = React.memo(Menu)
export default MemoMenu
