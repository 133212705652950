import * as React from "react"

function Rocket(props) {
  return (
    <svg viewBox="0 0 32 32" width="1em" height="1em" fill="currentColor" {...props}>
      <path d="M16 16c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z" />
      <path d="M26 23h-2.3c-1 0-2-.5-2.6-1.3-.6-.8-.8-1.8-.6-2.8.3-1 .4-2 .5-3v-.1-.9c0-4.7-2-8.9-5-10.7-3 1.8-5 6-5 10.7 0 1.4.2 2.8.5 4.1.3 1 .1 2-.6 2.8-.6.7-1.5 1.2-2.6 1.2H6c-.3 0-.6-.1-.8-.4-.2-.2-.2-.5-.2-.8.6-2.5 2-4.8 4-6.4v-.6C9 9.2 11.6 4 15.6 2.1c.3-.1.6-.1.9 0C20.4 4 23 9.2 23 14.8v.6c1.9 1.6 3.3 3.8 3.9 6.4.1.3 0 .6-.2.9-.1.2-.4.3-.7.3z" />
      <path d="M16 31c-.6 0-1-.4-1-1v-8c0-.6.4-1 1-1s1 .4 1 1v8c0 .6-.4 1-1 1zM13 29c-.6 0-1-.4-1-1v-4c0-.6.4-1 1-1s1 .4 1 1v4c0 .6-.4 1-1 1zM19 29c-.6 0-1-.4-1-1v-4c0-.6.4-1 1-1s1 .4 1 1v4c0 .6-.4 1-1 1z" />
    </svg>
  )
}

const MemoRocket = React.memo(Rocket)
export default MemoRocket
